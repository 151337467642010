function setFooterStyles() {
    let footerBlack = document.querySelector(".footer-black")
    let footerWhite = document.querySelector(".footer-white")
    // Add margin on footer for curtain effect
    if (footerBlack && footerWhite) {
        footerWhite.style.marginBottom = footerBlack.offsetHeight+'px'
    }
}

// Window onload (after all dom and images are loaded completely)
window.addEventListener('load', setFooterStyles)

//Recalculate black footer height on resize and set white footer margin accordingly
let resizeId;
window.addEventListener('resize', function() {
    clearTimeout(resizeId);
    resizeId = setTimeout(setFooterStyles, 500);
});

let footerVisible = false;
//Hide footer for longer pages
window.addEventListener("scroll", function(e) {
    let footerBlack = document.querySelector(".footer-black")
    let footerWhite = document.querySelector(".footer-white")

    const footerBottom = document.querySelector(".footer-white").offsetTop + document.querySelector(".footer-white").offsetHeight;
    const windowBottom = window.scrollY + window.innerHeight;
        
    if(windowBottom >= footerBottom) {
        if (footerVisible == false) {
            footerBlack.style.zIndex = "20";
            footerBlack.style.opacity = "1";
            footerBlack.style.pointerEvents = "auto";
            footerVisible = true;
        }
    } else {
        if (footerVisible == true) {
            footerBlack.style.zIndex = "-10";
            footerBlack.style.opacity = "0";
            footerBlack.style.pointerEvents = "none";
            footerVisible = false;
        }
    }
})




//Social media icons
let socialIconsContainer = document.querySelector(".footer-social-icon-container")
let iconsLists = document.querySelectorAll(".footer-social-icon-outer")

if (socialIconsContainer) {
    let containerHeight = socialIconsContainer.offsetHeight;

    iconsLists.forEach(function(iconList) {
        iconList.addEventListener("mouseover", function(e) {
            if(window.innerWidth > 1024) {
                e.stopPropagation();
                socialIconsContainer.style.height = `${containerHeight + e.target.closest(".footer-social-icon-outer").children[1].offsetHeight}px`;
            }
        })
        iconList.addEventListener("mouseleave", function(e) {
            if(window.innerWidth > 1024) {
                socialIconsContainer.style.height = `${containerHeight}px`;
            }
        })
    })
}