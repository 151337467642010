<template>
    <article style="transform: translateY(30px)">
        <a class="block w-full group" :href="article.url">
            <div class="pb-aspect70 relative mb-5 md:mb-8 bg-green text-white text-center overflow-hidden">
                <figure v-if="article.image" class="w-full h-full absolute top-0 left-0 bg-gray-card">
                    <img
                        class="w-full h-full object-cover"
                        :data-src="getSrc()"
                        :data-srcset="getSrcSet()"
                        :alt="article.title"
                    >
                    <div class="w-full h-full bg-black absolute left-0 top-0 opacity-0 group-hover:opacity-25 transition-opacity duration-300"></div>
                </figure>
            </div>
            <div class="flex flex-col items-center text-center px-4 md:px-8">
                <h3 v-if="article.categories" class="text-sm uppercase mb-3 md:mb-4 text-gray tracking-wide font-calibre-regular">{{ article.categories }}</h3>
                <h1 class="text-lg sm:text-3xl md:text-2xl lg:text-4xl uppercase mb-3 md:mb-4 tracking-widest">{{ article.title }}</h1>
            </div>
        </a>
    </article>
</template>

<script>
    export default {
        props: {
            article: {
                type: Object,
                required: true,
            },
        },

        methods: {
            getSrc() {
                return this.article.image[0];
            },

            getSrcSet() {
                return this.article.image.toString();
            }
        }
    }
</script>
