import PhotoSwipe from 'photoswipe';
import PhotoSwipeUI_Default from 'photoswipe/dist/photoswipe-ui-default';
import forEach from 'lodash/forEach';
import findIndex from 'lodash/findIndex';

class ImageGallery {

    constructor(options = {}) {

        this.options = Object.assign({
            trigger: '[data-gallery-trigger]',
            gallery: '[data-gallery-slides]',
        }, options);

        this.gallery = document.querySelectorAll(this.options.gallery);
        this.trigger = document.querySelectorAll(this.options.trigger);

        if (this.gallery) {
            document.body.insertAdjacentHTML('beforeend', this.galleryHtml());

            forEach(this.gallery, (gallery) => {

                let galleryImages = gallery.querySelectorAll('img');

                forEach(galleryImages, (img) => {

                    img.addEventListener('click', (e) => {

                        let chosenGallery = e.target.closest(this.options.gallery);
                        let currentImage = e.target;
                        let index = findIndex(galleryImages, (i) => {
                            return i.src == currentImage.src;
                        });

                        this.openGallery(chosenGallery, currentImage, index);

                    });

                });

            });

            forEach(this.trigger, (galleryTrigger) => {

                galleryTrigger.addEventListener('click', (e) => {

                    let galleryId = e.target.dataset.galleryTrigger;
                    let chosenGallery = document.getElementById(galleryId);
                    let currentImage = chosenGallery.querySelector('img');

                    this.openGallery(chosenGallery, currentImage);

                });

            });
        }
    }

    openGallery(gallery, currentImage, index = 0) {

        let items = JSON.parse(gallery.dataset.gallerySlides);
        let galleryElem = document.querySelector('.pswp');

        let options = {
            index: index,
            getThumbBoundsFn: () => {
                let pageYScroll = window.pageYOffset || document.documentElement.scrollTop;
                let rect = currentImage.getBoundingClientRect();
                return { x: rect.left, y: rect.top + pageYScroll, w: rect.width };
            },
            showHideOpacity: true,
            showAnimationDuration: 333,
            hideAnimationDuration: 333,
            bgOpacity: 0.9,
            spacing: 0,
            allowPanToNext: true,
            maxSpreadZoom: 3,
            loop: true,
            pinchToClose: true,
            closeOnScroll: false,
            closeOnVerticalDrag: true,
            mouseUsed: false,
            escKey: true,
            arrowKeys: true,
            history: false,
            errorMsg: '<div class="pswp__error-msg"><a href="%url%" target="_blank">The image</a> could not be loaded.</div>',
            focus: true,
            modal: true,
        };

        let photoswipe = new PhotoSwipe(
            galleryElem,
            PhotoSwipeUI_Default,
            items,
            options
        );

        photoswipe.listen('gettingData', function(index, item) {
            if (item.w < 1 || item.h < 1) {
                let img = new Image();
                img.onload = function() {
                    item.w = this.width;
                    item.h = this.height;
                    photoswipe.invalidateCurrItems();
                    photoswipe.updateSize(true);
                };
                img.src = item.src;
            }
        });

        photoswipe.init();

    }

    galleryHtml() {
        return `
            <div class="pswp" tabindex="-1" role="dialog" aria-hidden="true">
                <div class="pswp__bg"></div><div class="pswp__scroll-wrap">
                    <div class="pswp__container">
                        <div class="pswp__item"></div>
                        <div class="pswp__item"></div>
                        <div class="pswp__item"></div>
                    </div>
                    <div class="pswp__ui pswp__ui--hidden">
                        <div class="pswp__top-bar">
                            <div class="pswp__counter"></div>
                            <button class="pswp__button pswp__button--close" title="Close (Esc)"></button>
                            <div class="pswp__preloader">
                                <div class="pswp__preloader__icn">
                                    <div class="pswp__preloader__cut">
                                        <div class="pswp__preloader__donut"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="pswp__share-modal pswp__share-modal--hidden pswp__single-tap">
                            <div class="pswp__share-tooltip"></div>
                        </div>
                        <button class="pswp__button pswp__button--arrow--left" title="Previous (arrow left)"></button>
                        <button class="pswp__button pswp__button--arrow--right" title="Next (arrow right)"></button>
                        <div class="pswp__caption">
                            <div class="pswp__caption__center"></div>
                        </div>
                    </div>
                </div>
            </div>
        `;
    }
}

new ImageGallery();
