<template>
    <a class="block w-full group" :href="apartment.url" style="transform: translateY(30px)">
        <figure class="pb-aspect70 relative mb-5 md:mb-8 overflow-hidden bg-gray-card">
            <img
                class="w-full h-full object-cover absolute top-0 left-0 "
                style="transform: scale(1.15);"
                :data-src="apartment.image.url"
                :alt="apartment.image.alt"
            >
            <div v-if="apartment.status === 'viewing'">
                <div class="absolute top-0 left-0 w-full h-full bg-black opacity-50 z-10"></div>
                <div class="absolute top-0 left-0 w-full h-full flex items-center justify-center text-6xl leading-tight font-freightneopro-regular text-white px-2 lg:px-4 z-20"><span>{{ apartment.statusText }}</span></div>
            </div>
            <div class="w-full h-full bg-black absolute left-0 top-0 opacity-0 group-hover:opacity-25 transition-opacity duration-300"></div>
        </figure>
        <div class="flex flex-col items-center text-center md:px-4">
            <p class="text-sm uppercase mb-3 md:mb-4 text-gray tracking-wide font-calibre-regular">{{ apartment.title }}</p>
            <h2 class="text-lg sm:text-3xl md:text-2xl lg:text-4xl uppercase mb-3 md:mb-4 tracking-widest">{{ apartment.quarteroftown }}</h2>
            <div class="apartment-card-text flex items-center justify-center tracking-normal">
                <p v-if="apartment.price && apartment.price !== '&nbsp;'">{{ apartment.price }}</p>
                <p v-else-if="apartment.rent && apartment.rent !== '&nbsp;'">{{ apartment.rent }}</p>
                <p v-if="apartment.propertySize && apartment.propertySize !== '&nbsp;'">{{ apartment.propertySize }}</p>
                <p v-if="apartment.roomcount && apartment.roomcount !== '&nbsp;' && apartment.roomcount !== '0 rum' && apartment.roomcount !== '0 huonetta'">{{ apartment.roomcount }}</p>
                <p v-if="apartment.presentationStartDate && apartment.presentationStartDate !== '&nbsp;'">{{ apartment.presentationStartDate }}</p>
            </div>
        </div>
    </a>
</template>

<script>
    export default {
        props: {
            apartment: {
                type: Object,
                required: true,
            },
        }
    }
</script>
