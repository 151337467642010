export default function BrokersScrollAnimation(brokersCards) {

    if (window.innerWidth >= 1024) {
        //Query cards and filter
        let brokerFilter = document.querySelector(".brokers-filter")
        let brokerCardsContainer = document.querySelector(".broker-cards-container")
        let brokerCards = document.querySelectorAll(brokersCards)

        if(brokerCards.length < 3) {
            return
        }

        //Defining variable used for animations
        let brokerAnchor = []
        let brokerCardImgs = []
        let brokerCardImgsSrc = []
        let brokerCardImgsBg = []
        let brokerCardTextContent = []

        //Queryin and setting elements used for animations
        brokerCards.forEach(function(brokerCard) {
            brokerAnchor.push(brokerCard.querySelector("a"));
            brokerCardImgs.push(brokerCard.querySelector("figure"));
            brokerCardImgsSrc.push(brokerCard.querySelector("img").getAttribute('data-src'));
            brokerCardTextContent.push(brokerCard.querySelector(".broker-card-content-top"));
            brokerCardTextContent.push(brokerCard.querySelector(".broker-card-content-bottom"));

            let computedStyles = window.getComputedStyle(brokerCard.querySelector("figure"))
            brokerCardImgsBg.push(computedStyles.getPropertyValue('background-color'))
        })

        //Defining images respective to their position
        let imgLeft, imgCenter, imgRight

        imgLeft = brokerCardImgs[0];
        imgCenter = brokerCardImgs[1];
        imgRight = brokerCardImgs[2];

        //Scaling images to determine translateX percentage
        imgLeft.style.transform = "scale(1.8)";
        imgCenter.style.transform = "scale(2)";
        imgRight.style.transform = "scale(1.8)";

        let scaledPosImgLeft = imgLeft.getBoundingClientRect();
        let scaledPosImgCenter = imgCenter.getBoundingClientRect();

        let reqMovement = (scaledPosImgCenter.left-scaledPosImgLeft.left+5)*2 / scaledPosImgLeft.width * 100

        //Initial State on load
        imgLeft.style.transform = `translateX(${reqMovement}%) scale(1.8)`
        brokerCards[1].style.zIndex = "999";
        imgRight.style.transform = `translateX(${-reqMovement}%) scale(1.8)`

        brokerAnchor.forEach(function(textContent) {
            textContent.style.transform = "translateY(0)"
        })

        brokerCardTextContent.forEach(function(textContent) {
            textContent.style.transform = "translateY(30px)"
            textContent.style.opacity = "0"
            textContent.classList.remove("show-card-text");
        })

        //Adding new element
        var slideContainer = document.createElement("img");
        slideContainer.style.position = `absolute`
        slideContainer.style.zIndex = `9999`
        slideContainer.style.height = `${scaledPosImgCenter.height}px`
        slideContainer.style.width = `${scaledPosImgCenter.width}px`
        slideContainer.style.backgroundColor = `#DDD1C7`
        slideContainer.style.objectFit = `cover`
        imgCenter.appendChild(slideContainer)


        // Defining scroll start and end
        let scrollStart = 200
        let scrollEnd = 480
        let scrollLength = scrollEnd - scrollStart
        let canAnimate = true

        //Adding animation to new element
        var slideIndex = 0;

        function slideshow() {
            slideIndex++;
            if (slideIndex > 3) {slideIndex = 1}
            slideContainer.style.backgroundColor = `${brokerCardImgsBg[slideIndex - 1]}`
            slideContainer.setAttribute("src", `${brokerCardImgsSrc[slideIndex - 1]}`)
            if (canAnimate) {
                setTimeout(slideshow, 1000);
            }
        }
        slideshow();

        function animate() {
            if(canAnimate) {
                if(window.pageYOffset < scrollStart) {
                    imgLeft.style.transform = `translateX(${reqMovement}%) scale(1.8)`
                    imgCenter.style.transform = "scale(2)";
                    imgRight.style.transform = `translateX(${-reqMovement}%) scale(1.8)`

                    brokerCardTextContent.forEach(function(textContent) {
                        textContent.style.transform = "translateY(30px)"
                        textContent.style.opacity = "0"
                        textContent.classList.remove("show-card-text");
                    })

                    slideContainer.classList.remove("hidden")
                    if (brokerFilter) {
                        brokerFilter.classList.add("lg:opacity-0", "lg:pointer-events-none")
                    }
                }
                else if(window.pageYOffset > scrollStart && window.pageYOffset < scrollEnd) {
                    let offset = window.pageYOffset
                    imgLeft.style.transform = `translateX(${reqMovement - reqMovement*(offset - scrollStart)/scrollLength}%) scale(${1.8 - 0.8*(offset-scrollStart)/scrollLength})`
                    imgCenter.style.transform = `scale(${2 - (offset-scrollStart)/scrollLength})`
                    imgRight.style.transform = `translateX(${-reqMovement + reqMovement*(offset - scrollStart)/scrollLength}%) scale(${1.8 - 0.8*(offset-scrollStart)/scrollLength})`

                    brokerCardTextContent.forEach(function(textContent) {
                        textContent.style.transform = "translateY(30px)"
                        textContent.style.opacity = "0"
                        textContent.classList.remove("show-card-text");
                    })

                    slideContainer.classList.add("hidden")
                    if (brokerFilter) {
                        brokerFilter.classList.add("lg:opacity-0", "lg:pointer-events-none")
                    }
                } else {
                    imgLeft.style.transform = `translateX(0%) scale(1)`
                    imgCenter.style.transform = `translateX(0%) scale(1)`
                    imgRight.style.transform = `translateX(0%) scale(1)`

                    brokerCardTextContent.forEach(function(textContent) {
                        textContent.style.transform = "translateY(0px)"
                        textContent.style.opacity = "1"
                    })
                    if (brokerFilter) {
                        brokerFilter.classList.remove("lg:opacity-0", "lg:pointer-events-none")
                    }
                    canAnimate = false
                }
            } else {
                slideContainer.classList.add("hidden")
            }
        }

        // initialize animation on page load
        animate();

        setTimeout(animate, 200);

        //Scroll Listener
        window.addEventListener("scroll", function(){
            animate();
        })
    }
}
