import find from 'lodash/find';

class MostlyVisible {
    constructor() {
        this.data = {
            elements: [],
            mostlyVisibleElement: null,
        };
    }

    setElements(elements) {
        this.data.elements.splice(0, elements.length, ...elements);
    }

    async getVisibleElement() {
        let mostlyVisibleElement = find(this.data.elements, (element) => {
            return this.mostlyVisible(element);
        });

        if (typeof mostlyVisibleElement !== 'undefined' && this.data.mostlyVisibleElement !== mostlyVisibleElement) {
            this.data.mostlyVisibleElement = mostlyVisibleElement;

            return this.data.mostlyVisibleElement;
        }

        return null;
    }

    mostlyVisible(element) {
        let windowHeight = window.innerHeight;
        let scrollPos = window.scrollY;
        let elementTop = element.getBoundingClientRect().top + document.documentElement.scrollTop;
        let elementHeight = element.offsetHeight;
        let elementBottom = elementTop + elementHeight;

        return ((elementBottom - elementHeight * 0.25 > scrollPos) && (elementTop < (scrollPos + 0.5 * windowHeight)));
    }
}

const mostlyVisibleInstance = new MostlyVisible();
Object.freeze(mostlyVisibleInstance);

export default mostlyVisibleInstance;
