import axios from 'axios';

class CsrfToken {

    constructor() {
        const csrfTokenName = AKTIALKV.csrfTokenName;

        this.csrfInputs = document.querySelectorAll('input[name=' + csrfTokenName + ']');

        if (this.csrfInputs.length) {
            this.setCsrfToken();
        }
    }

    setCsrfToken(input) {
        axios.get('/api/csrf-token', {
            baseURL: AKTIALKV.siteUrl,
        }).then(({ data }) => {
            this.updateCsrfToken(data.csrfToken);
        }).catch(error => {
            console.log(error);
        })
    }

    updateCsrfToken(csrfToken) {
        this.csrfInputs.forEach(input => {
            input.value = csrfToken;
        });
    }
}

new CsrfToken();
