<template>
    <div class="relative xxs:w-full md:w-auto">
        <select class="xxs:w-full bg-transparent cursor-pointer hover:opacity-50 transition-opacity duration-300 font-calibre-regular pr-4" @change="handleChange">
            <option value="">{{ label }}</option>
            <option class="text-right" v-for="item, key in items" :selected="selected === key" :value="key" :key="key">{{ item }}</option>
        </select>
    </div>
</template>

<script>
    export default {
        props: {
            label: {
                type: String,
                default: '',
            },

            items: {
                type: Object,
                default: {},
            },
            selected: {
                type: String,
                default: ''
            }
        },
        methods: {
            handleChange({target}) {
                this.$emit('change', target.value);
            }
        }
    }
</script>
