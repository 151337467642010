import forEach from 'lodash/forEach';
import find from 'lodash/find';
import head from 'lodash/head';

class Tabs {

    constructor(element) {
        this.element = element;
        this.tabs = element.querySelectorAll('[data-tab]');
        this.tabTriggers = document.querySelectorAll('[data-tab-trigger]');

        if (this.tabs.length) {
            this.panes = [];

            // Collect panes belonging to the tabs and add click event.
            forEach(this.tabs, (tab) => {
                this.clickTabAction(tab);
                this.panes.push(document.querySelector(tab.hash));
            });

            // Add click events for tab triggers.
            forEach(this.tabTriggers, (tabTrigger) => {
                this.clickTabTriggerAction(tabTrigger);
            })

            // Set initial active tab.
            this.activeTab = this.getActiveTab();
            // Set initial active pane.
            this.activePane = this.getActivePane();

            this.toggleTabs(this.activeTab);
            this.togglePanes(this.activePane);
            this.toggleDropDown();
        }
    }

    clickTabAction(tab) {
        tab.addEventListener('click', (e) => {
            e.preventDefault();

            let tab = e.target;
            let pane = document.querySelector(tab.hash);

            this.togglePanes(pane);
            this.toggleTabs(tab);
            this.toggleDropDown();
            this.element.parentElement.scrollIntoView();
        });
    }

    clickTabTriggerAction(tabTrigger) {
        tabTrigger.addEventListener('click', (e) => {
            e.preventDefault();

            let tab = document.querySelector('[href="' +  e.target.hash + '"][data-tab]');

            if (tab) {
                let pane = document.querySelector(tab.hash);
                this.togglePanes(pane);
                this.toggleTabs(tab);
                this.element.parentElement.scrollIntoView();
            }
        });
    }

    togglePanes(activePane) {
        forEach(this.panes, (pane) => {
            if (pane == activePane) {
                pane.classList.remove('pane-hidden');
            } else {
                pane.classList.add('pane-hidden');
            }
        });
    }

    toggleTabs(activeTab) {

        if (activeTab != this.activeTab) {
            this.activeTab = activeTab;
            history.replaceState(null, null, activeTab.hash);
        }

        forEach(this.tabs, (tab) => {
            if (tab == activeTab) {
                tab.classList.add('tabs__link--active');
            } else {
                tab.classList.remove('tabs__link--active');
            }
        });

        this.updateDropDownTrigger();
    }

    getActiveTab() {
        let activeTab = find(this.tabs, (tab) => {
            return tab.hash == window.location.hash;
        });

        return activeTab ? activeTab : head(this.tabs);
    }

    getActivePane() {
        let activePane = find(this.panes, (pane) => {
            return pane.id == window.location.hash.substring(1);
        });

        return activePane ? activePane : head(this.panes);
    }

    updateDropDownTrigger() {
        if (!this.dropDownTrigger) {
            const dropDownTrigger = document.createElement('div');
            dropDownTrigger.classList = 'tabs-dropdown-trigger';
            dropDownTrigger.addEventListener('click', () => this.toggleDropDown());
            dropDownTrigger.innerHTML = this.dropDownContent(this.activeTab);
            this.dropDownTrigger = this.element.parentElement.insertBefore(dropDownTrigger, this.element);
        } else {
            this.dropDownTrigger.innerHTML = this.dropDownContent(this.activeTab);
        }
    }

    dropDownContent(activeTab) {
        const iconUrl = require('../../icons/dd-arrow.svg');

        return `
            <button class="flex">
                <span>${activeTab.textContent}</span>
                <span>${iconUrl}</span>
            </button>
        `;
    }

    toggleDropDown() {
        this.element.classList.toggle('dropdown-hidden');
    }

}

forEach(document.querySelectorAll('[data-tabs]'), (tab) => new Tabs(tab));
