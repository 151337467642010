<template>
    <div>
        <div v-if="showTitle" class="max-w-content-narrower mx-auto text-center mb-12 md:mb-16 xl:mb-24">
            <h2 class="text-5xl md:text-6xl lg:text-7xl mb-8 md:mb-12 lg:mb-20 xl:mb-24 font-freightneopro-regular leading-none">{{ title }}</h2>
        </div>
        <div class="max-w-content mx-auto tracking-normal">
            <div v-if="showFilters" class="brokers-filter flex justify-between items-center mb-6 font-calibre-regular lg:opacity-0 lg:pointer-events-none transition duration-200">
                <div class="w-1/4 flex items-center">
                    <span class="mr-3 mt-0-5">
                        <svg role="img" width="12" height="12">
                            <use xlink:href="/assets/images/icons.svg#icon-search"></use>
                        </svg>
                    </span>
                    <input @keyup="handleInputSubmit" :value="search" class="bg-transparent placeholder-gray-light" type="text" :placeholder="strings.searchBroker">
                </div>
                <div class="flex items-center">
                    <SelectList :label="strings.chooseArea" :items="filters" @change="handleChooseAreaChange"></SelectList>
                </div>
            </div>
            <template v-if="items.length > 0">
                <div class="broker-cards-container flex flex-wrap sm:-mx-3 xl:-mx-6 xxl:-mx-9 px-10 sm:px-0">
                    <article v-for="(item, index) in items" :key="item.id" class="broker-card w-full sm:w-1/2 md:w-1/3 sm:px-3 xl:px-6 xxl:px-9 mb-12 lg:mb-20 animate-on-scroll opacity-0" :class="itemClasses(item.type, index)" data-scroll-offset=".9">
                        <a :href="item.url" class="block w-full group mb-4 md:mb-6" style="transform: translateY(30px)">
                            <figure class="broker-card-bg pb-aspect125 w-full h-full relative mb-8 overflow-hidden" :style="{ backgroundColor: item.bgColor }">
                                <img class="absolute w-full h-full object-cover object-top" style="margin-top: 75px; transform: scale(1.25);" :data-src="item.brokerBackground">
                            </figure>
                            <div class="broker-card-content-top flex flex-col items-center justify-center tracking-normal">
                                <p class="text-sm uppercase mb-4 md:mb-6 tracking-wide group-hover:opacity-50 transition-opacity duration-300">{{ item.jobTitle }}</p>
                                <h3 class="text-2xl sm:text-3xl uppercase text-center tracking-widest group-hover:opacity-50 transition-opacity duration-300">{{ item.name }}</h3>
                            </div>
                        </a>
                        <div class="broker-card-content-bottom flex flex-col items-center" style="transform: translateY(30px)">
                            <p class="mb-1">
                                <a class="hover:opacity-50 transition-opacity duration-300" :href="`mailto:${item.email}`">{{ item.email }}</a>
                            </p>
                            <p class="mb-4 md:mb-6" v-if="item.phoneNumber">
                                <a class="hover:opacity-50 transition-opacity duration-300" :href="`tel:${item.phoneNumber.replace(/ /g, '')}`">{{ item.phoneNumber }}</a>
                            </p>
                            <a v-if="item.city.title !== null" class="flex flex-col items-center hover:opacity-50 transition-opacity duration-300" :href="item.city.url">
                                <span class="mb-2">
                                    <svg class="icon" role="img" width="12" height="12">
                                        <use xlink:href="/assets/images/icons.svg#icon-location"></use>
                                    </svg>
                                </span>
                                <span class="">{{ item.city.title }}</span>
                            </a>
                        </div>
                    </article>
                </div>
            </template>
            <template v-else>
                <div class="max-w-content mx-auto tracking-normal text-center">
                    <h2 class="text-5xl lg:text-6xl my-8 md:my-12 lg:my-20 xl:my-24 font-freightneopro-regular leading-none">{{ strings.noResult }}</h2>
                </div>
                <div class="broker-cards-container flex flex-wrap sm:-mx-3 xl:-mx-6 xxl:-mx-9 px-10 sm:px-0">
                    <article v-for="(item, index) in allItems" :key="item.id" class="broker-card w-full sm:w-1/2 md:w-1/3 sm:px-3 xl:px-6 xxl:px-9 mb-12 lg:mb-20 animate-on-scroll opacity-0" :class="itemClasses(item.type, index)" data-scroll-offset=".9">
                        <a :href="item.url" class="block w-full group mb-4 md:mb-6" style="transform: translateY(30px)">
                            <figure class="broker-card-bg pb-aspect125 w-full h-full relative mb-8 overflow-hidden" :style="{ backgroundColor: item.bgColor }">
                                <img class="absolute w-full h-full object-cover object-top" style="margin-top: 75px; transform: scale(1.25);" :data-src="item.brokerBackground">
                            </figure>
                            <div class="broker-card-content-top flex flex-col items-center justify-center tracking-normal">
                                <p class="text-sm uppercase mb-4 md:mb-6 tracking-wide group-hover:opacity-50 transition-opacity duration-300">{{ item.jobTitle }}</p>
                                <h3 class="text-2xl sm:text-3xl uppercase text-center tracking-widest group-hover:opacity-50 transition-opacity duration-300">{{ item.name }}</h3>
                            </div>
                        </a>
                        <div class="broker-card-content-bottom flex flex-col items-center" style="transform: translateY(30px)">
                            <p class="mb-1">
                                <a class="hover:opacity-50 transition-opacity duration-300" :href="`mailto:${item.email}`">{{ item.email }}</a>
                            </p>
                            <p class="mb-4 md:mb-6" v-if="item.phoneNumber">
                                <a class="hover:opacity-50 transition-opacity duration-300" :href="`tel:${item.phoneNumber.replace(/ /g, '')}`">{{ item.phoneNumber }}</a>
                            </p>
                            <a v-if="item.city.title !== null" class="flex flex-col items-center hover:opacity-50 transition-opacity duration-300" :href="item.city.url">
                                <span class="mb-2">
                                    <svg class="icon" role="img" width="12" height="12">
                                        <use xlink:href="/assets/images/icons.svg#icon-location"></use>
                                    </svg>
                                </span>
                                <span class="">{{ item.city.title }}</span>
                            </a>
                        </div>
                    </article>
                </div>
            </template>
            </div>
            <div v-if="items.length == 0" class="mt-12 text-center">
                <LoadingBars></LoadingBars>
            </div>
        </div>
    </div>
</template>

<script>

    import axios from 'axios';
    import forEach from 'lodash/forEach';
    import debounce from 'lodash/debounce';
    import SelectList from './SelectList';
    import LoadingBars from './LoadingBars';
    import AnimateOnScroll from '../common/animateOnScroll';
    import BrokersScrollAnimation from '../common/brokersScrollAnimation';
    import lazyload from '../common/lazyload';

    export default {
        components: {
            SelectList,
            LoadingBars,
        },

        props: {
            title: {
                type: String,
            },
            region: {
                type: Array,
            },
            strings: {
                type: Object,
            },
            showFilters: {
                type: Boolean,
                default: true,
            },
            exclude: {
                type: Array,
            }
        },

        data() {
            return {
                items: [],
                allItems: [],
                filters: {},
                categories: [],
                search: '',
                filteredOnce: false,
            }
        },

        mounted() {
            this.fetchBrokers(true);
        },

        methods: {
            handleChooseAreaChange(key) {
                this.categories = key ? [key] : [];
                this.fetchBrokers();
                this.filteredOnce = true;
            },

            handleInputSubmit: debounce(function({target}) {
                this.search = target.value;
                this.fetchBrokers();
                this.filteredOnce = true;
            }, 1000),

            fetchBrokers(all) {
                axios.get('/api/brokers', {
                    baseURL: AKTIALKV.siteUrl,
                    params: {
                        region: this.region,
                        categories: this.categories,
                        search: this.search.length ? this.search : null,
                        exclude: this.exclude,
                    }
                }).then(({ data }) => {
                    this.items = data.brokers;
                    this.filters = {...data.filters};
                    if (all) {
                        this.allItems = data.brokers;
                    }
                }).catch(error => {
                    console.log(error);
                    this.items = [];
                })
            },

            itemClasses(type, index) {
                let classes = ''
                if (window.innerWidth >= 1024) {
                    if(index < 3) {
                        if(!this.filteredOnce) {
                            classes = `${classes} in-view animate-card`
                        } else {
                            classes = `${classes}`
                        }
                    }
                    if(type != 'divider') {
                        classes = `${classes} md:w-1/2`
                    }
                    return classes
                }
            },
        },

        computed: {
            showTitle() {
                return this.title && this.items.length;
            }
        },

        watch: {
            items() {
                this.$nextTick(() => {
                    const images = this.$el.querySelectorAll('img');
                    // Remove lazyload status attribute so that images are updated.
                    forEach(images, function(el) {
                        el.removeAttribute('data-ll-status');
                    });
                    lazyload.update();

                    const animatedElements = this.$el.querySelectorAll('.animate-on-scroll');
                    new AnimateOnScroll(animatedElements);

                    BrokersScrollAnimation('.animate-card')
                })
            }
        }
    }
</script>

<style scoped>
.broker-card-content-top, .broker-card-content-bottom {
    transition: all 800ms;
}

</style>
