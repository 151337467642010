<template>
    <div>
        <div v-if="showFilters" class="al-filter flex flex-wrap justify-between items-center pb-6 md:pb-4 lg:pb-0 font-calibre-regular tracking-normal opacity-0 h-0 md:px-3 xl:px-8">
            <div class="md:hidden w-full xxs:w-1/2 text-center xxs:text-left pr-4 md:pr-0">
                <div class="relative xxs:w-full md:w-auto">
                    <select @change="handleCategoryClick($event.targe.value)" class="xxs:w-full bg-transparent cursor-pointer hover:opacity-50 transition-opacity duration-300 font-calibre-regular pr-4">
                        <option v-for="realtytype, key in realtytypes" :key="key" :value="key" :class="realtyTypeClasses(key)">{{ realtytype }}</option>
                    </select>
                </div>
            </div>
            <div class="hidden md:block w-2/4 uppercase text-sm text-center tracking-wide md:order-2">
                <a class="cursor-pointer" v-for="realtytype, key in realtytypes" :key="key" :class="realtyTypeClasses(key)" @click="handleCategoryClick(key)">
                    <span style="margin-right: -2px">{{ realtytype }}</span>
                </a>
            </div>
            <div class="w-1/2 xxs:w-1/2 md:w-1/4 flex mb-1 md:order-3 pl-4 md:pl-0 md:justify-end">
                <SelectList :selected="orderBy" :label="strings.sortBy" :items="sorting" @change="handleOrderByChange"></SelectList>
            </div>            
            <div class="w-1/2 xxs:w-1/2 md:w-1/4 flex items-center md:order-1 xxs:pr-4 pt-8 md:pt-0">
                <div class="relative xxs:w-full md:w-auto" v-if="allRegions.length > 1">
                    <select class="xxs:w-full bg-transparent cursor-pointer hover:opacity-50 transition-opacity duration-300 font-calibre-regular pr-4" @change="handleRegionChange">
                        <option class="text-left" v-for="item, key in allRegions" :selected="item.id === parseInt(region[0])" :value="item.url" :key="key">{{ item.title }}</option>
                    </select>
                </div>
            </div>            
        </div>
        
        <div class="flex flex-wrap">
          <template v-for="(apartments, groupIndex) in apartmentBatches">
              <article v-for="(item, index) in apartments" class="w-full md:px-3 xl:px-8 mb-12 lg:mb-16 xl:mb-20 animate-on-scroll opacity-0" :class="itemClasses(item.type, index)" data-scroll-offset=".9" :data-page="groupIndex + 1">
                  <ApartmentCard v-if="item.type == 'apartment' || item.type == 'soldApartment'" :apartment="item"></ApartmentCard>
                  <template v-if="item.type == 'banner'">
                      <BannerBroker v-if="item.bannerType == 'broker'" :banner="item" :strings="strings"></BannerBroker>
                      <BannerArticle v-else-if="item.bannerType == 'article'" :banner="item"></BannerArticle>
                      <BannerPage v-else-if="item.bannerType == 'page'" :banner="item"></BannerPage>
                      <Banner v-else :banner="item"></Banner>
                  </template>
                  <div v-if="item.type == 'divider'">
                      <h2 class="text-2xl text-center sm:text-3xl md:text-2xl lg:text-4xl uppercase mt-8 md:mt-16 tracking-widest">{{ item.title }}</h2>
                  </div>
                  <ApartmentSkeleton v-if="item.type == 'apartmentSkeleton'"></ApartmentSkeleton>
              </article>
          </template>
        </div>
        <InfiniteLoading :identifier="infiniteId" @infinite="fetchApartments"></InfiniteLoading>
    </div>
</template>

<script>

    import Vue from 'vue';
    import axios from 'axios';
    import buildURL from 'axios/lib/helpers/buildURL';
    import debounce from 'lodash/debounce';
    import forEach from 'lodash/forEach';
    import throttle from 'lodash/throttle';
    import find from 'lodash/find';
    import ApartmentCard from './ApartmentCard';
    import Banner from './Banner';
    import BannerArticle from './BannerArticle';
    import BannerBroker from './BannerBroker';
    import BannerPage from './BannerPage';
    import SelectList from './SelectList';
    import ApartmentSkeleton from './ApartmentSkeleton';
    import GoogleMap from './GoogleMap';
    import InfiniteLoading from 'vue-infinite-loading';
    import queryString from 'query-string';
    import AnimateOnScroll from '../common/animateOnScroll';
    import ApartmentLandingAnimation from '../components/apartmentLandingAnimation';
    import lazyload from '../common/lazyload';
    import mostlyVisible from '../common/mostlyVisible';

    export default {
        components: {
            ApartmentCard,
            Banner,
            BannerArticle,
            BannerBroker,
            BannerPage,
            SelectList,
            InfiniteLoading,
            ApartmentSkeleton,
        },

        props: {
            region: {
                type: Array,
            },
            quarterOfTownFilter: {
                type: Array,
            },
            allRegions: {
                type: Array,
            },
            strings: {
                // type: Object,
                validator: prop => typeof prop === 'array' || typeof prop === 'object',
            },
            forRent: {
                type: Boolean,
                default: false,
            },
            showFilters: {
                type: Boolean,
                default: true,
            },
            limit: {
                validator: prop => typeof prop === 'number' || prop === null,
                default: null,
            },
            exclude: {
                type: Array,
            },
            pageNum: {
                type: Number,
                default: 1,
            },
            offset: {
                type: Number,
                default: 0,
            }
        },

        data() {
            return {
                apartmentBatches: [],
                realtytypes: [],
                sorting: {},
                orderBy: null,
                objectId: '',
                pageNumber: 1,
                categories: [],
                markers: [],
                infiniteId: +new Date(),
            }
        },

        mounted() {
            if (!this.limit) {
                // Get query params.
                let params = queryString.parse(location.search, {arrayFormat: 'bracket'});

                // Update data with param values.
                Object.assign(this.$data, params);

                // Add placeholder apartments while the real ones are loading.
                this.addDummyApartments(this.pageNum);

                // Fetch previous apartment pages.
                this.fetchPreviousApartments(this.pageNum);

                // Fetch map markers.
                this.fetchMarkers();

                // Update page number when scrolling.
                window.addEventListener('scroll', throttle(() => {
                    mostlyVisible
                        .getVisibleElement()
                        .then((element) => {
                            if (element) {
                                this.historyPushState(element.dataset.page);
                            }
                        });
                }, 200), false);
            }
        },

        methods: {
            handleCategoryClick(id) {
                this.categories = [id];
                this.pageNumber = 1;
                this.apartmentBatches = [];
                this.infiniteId += 1;
                this.historyPushState();
            },

            handleOrderByChange(key) {
                this.orderBy = key;
                this.pageNumber = 1;
                this.apartmentBatches = [];
                this.infiniteId += 1;
                this.historyPushState();
            },

            handleInputSubmit: debounce(function({target}) {
                this.categories = [];
                this.objectId = target.value;
                this.pageNumber = 1;
                this.apartmentBatches = [];
                this.infiniteId += 1;
                this.historyPushState();
            }, 1000),

            historyPushState(page = null) {
                let apartmentsPath = '';

                if (page) {
                    apartmentsPath = location.pathname.replace(/\/?(page-\d+)?\/?$/, '');
                    apartmentsPath += '/page-' + page;
                }

                let url = buildURL(
                    apartmentsPath,
                    {
                        objectId: this.objectId.length ? this.objectId : null,
                        categories: this.categories,
                        orderBy: this.orderBy,
                    },
                    null
                );

                history.pushState({}, null, url);
            },

            handleRegionChange({target}) {
                let url = buildURL(
                    target.value,
                    {
                        objectId: this.objectId.length ? this.objectId : null,
                        categories: this.categories,
                        orderBy: this.orderBy,
                    },
                    null
                );
                window.location.href = url;
            },

            fetchApartments($state) {
                let request = axios.get('/api/apartments', {
                    baseURL: AKTIALKV.siteUrl,
                    params: {
                        region: this.region,
                        quarterOfTownFilter: this.quarterOfTownFilter,
                        categories: this.categories.includes('0') ? null : this.categories,
                        orderBy: this.orderBy,
                        page: this.pageNumber,
                        objectId: this.objectId.length ? this.objectId : null,
                        forRent: this.forRent,
                        exclude: this.exclude,
                        limit: this.limit,
                        offset: this.offset,
                    }
                }).then(({ data }) => {

                    let noApartmentsFound = !data.apartments.length;
                    let limitFull = (this.limit && this.limit <= data.apartments.length);
                    let allItemsLoaded = (this.limit && this.limit <= this.apartmentsLength);

                    if (!noApartmentsFound) {
                        this.apartmentBatches.splice(this.pageNumber - 1, 1, data.apartments);
                        this.realtytypes = data.realtytype;
                        this.sorting = data.orderBy;
                        this.pageNumber++;
                    }

                    if (noApartmentsFound || limitFull || allItemsLoaded) {
                        $state.complete();
                    } else {
                        $state.loaded();
                    }

                }).catch(error => {
                    console.log(error);
                })

                return request;
            },

            async fetchPreviousApartments(pageNumber) {
                let limit = pageNumber;
                let i = (pageNumber - pageNumber);

                // Get the infinite loading component.
                let infiniteLoading = find(this.$children, (c) => {
                    return typeof c.stateChanger !== 'undefined';
                });

                infiniteLoading.stateChanger.complete();

                while (i++ < limit) {
                    await this.fetchApartments(infiniteLoading.stateChanger);
                }

                infiniteLoading.stateChanger.reset();
            },

            fetchMarkers() {
                axios.get('/api/apartments/coordinates', {
                    baseURL: AKTIALKV.siteUrl,
                    params: {
                        region: this.region,
                        quarterOfTownFilter: this.quarterOfTownFilter,
                        forRent: this.forRent,
                    }
                }).then(({ data }) => {
                    this.markers.push(...data.markers);
                }).catch(error => {
                    console.log(error);
                })
            },

            realtyTypeClasses(key) {
                return {
                    'mx-2 lg:mx-4 py-1': true,
                    'border-b border-gray-lightest': (key == 0 && !this.categories.length) || this.categories.includes(key),
                }
            },

            itemClasses(type, index) {
                let classes = ''
                if (index < 2) {
                    classes = ['in-view'];
                }
                if (type != 'divider' && type != 'pageDivider') {
                    classes = ['md:w-1/2'];
                }
                return classes
            },

            addDummyApartments(pageNumber) {
                while (this.apartmentBatches.length < pageNumber) {

                    let apartments = [];

                    while (apartments.length < 24) {
                        apartments.push({
                            type: 'apartmentSkeleton',
                        })
                    }

                    this.apartmentBatches.splice(pageNumber - 1, 1, apartments);
                }
            }
        },

        computed: {
            apartmentsLength: function() {
                let length = 0;

                forEach(this.apartmentBatches, (apartmentBatch) => {
                    length += apartmentBatch.length;
                })

                return length;
            }
        },

        watch: {
            apartmentBatches() {
                this.$nextTick(() => {
                    const images = this.$el.querySelectorAll('img');

                    // Remove lazyload status attribute so that images are updated.
                    forEach(images, function(el) {
                        el.removeAttribute('data-ll-status');
                    });
                    lazyload.update();

                    const animatedElements = this.$el.querySelectorAll('.animate-on-scroll');
                    new AnimateOnScroll(animatedElements);

                    // const alFilter = this.$el.querySelector('.al-filter');
                    ApartmentLandingAnimation(".al-filter")

                    // Update page divider elements.
                    const pageDividers = this.$el.querySelectorAll('[data-page]');
                    mostlyVisible.setElements(pageDividers);
                })
            },

            markers() {
                new GoogleMap('map', this.markers);
            }
        },
    }
</script>
