var navOuter = document.querySelector(".main-nav-outer--sticky");
var navBar = document.querySelector("nav");
var hamburgerLines = document.querySelectorAll(".line-white");

if (typeof(navOuter) != 'undefined' && navOuter != null) {
    window.addEventListener("scroll", function(e) {
        if(window.innerWidth < 768) {
            if(navOuter.classList.contains('text-white')) {
                if(window.scrollY > 0 || window.pageYOffset > 0 || document.documentElement.scrollTop > 0) {
                    navBar.classList.add("fixed-nav");
                    navOuter.classList.add("text-gray-dark");
                    hamburgerLines.forEach(function(line){
                        line.style.borderColor = "black"
                    })
                } else {
                  navBar.classList.remove("fixed-nav");
                  navOuter.classList.remove("text-gray-dark");
                  hamburgerLines.forEach(function(line){
                        line.style.borderColor = "white"
                    })
                }
            } else {
                if(window.scrollY > 0 || window.pageYOffset > 0 || document.documentElement.scrollTop > 0) {
                    navBar.classList.add("fixed-nav");
                } else {
                  navBar.classList.remove("fixed-nav");
                }
            }
        }
    })
}
