<template>
    <div class="max-w-content mx-auto">
        <div class="text-center">
            <SelectList :label="strings.chooseArea" :items="filters" @change="handleChooseAreaChange"></SelectList>
        </div>
        <div class="flex flex-wrap -mx-6 xxl:-mx-9">
            <div v-for="item in items" class="w-full sm:w-1/2 md:w-1/3 px-6 xxl:px-9 mt-12 md:mt-16 xl:mt-24 animate-on-scroll opacity-0" data-scroll-offset=".9">
                <div class="w-full h-full flex flex-col items-center justify-between text-center tracking-normal"  style="transform: translateY(30px)">
                    <div>
                        <a class="inline-block text-3xl md:text-4xl uppercase mb-6 tracking-widest text-center hover:opacity-50 transition-opacity duration-300" :href="item.url">{{ item.title }}</a>
                        <p class="mb-6">{{ item.streetAddress }}, {{ item.title }}</p>
                        <div class="mb-3">
                            <a class="hover:opacity-50 transition-opacity duration-300" :href="`mailto:${item.email}`">{{ item.email }}</a>
                        </div>
                        <div class="mb-12 md:mb-16 xl:mb-20" v-if="item.phoneNumber">
                            <a class="hover:opacity-50 transition-opacity duration-300" :href="`tel:${item.phoneNumber.replace(/ /g, '')}`">{{ item.phoneNumber }}</a>
                        </div>
                    </div>
                    <span class="w-9 border-b border-black"></span>
                </div>
            </div>
        </div>
        <div v-if="items.length == 0" class="mt-12 text-center">
            <LoadingBars></LoadingBars>
        </div>
    </div>
</template>

<script>

    import axios from 'axios';
    import forEach from 'lodash/forEach';
    import SelectList from './SelectList';
    import LoadingBars from './LoadingBars';
    import AnimateOnScroll from '../common/animateOnScroll';
    import lazyload from '../common/lazyload';

    export default {
        components: {
            SelectList,
            LoadingBars,
        },

        props: {
            region: {
                type: Array,
            },
            strings: {
                type: Object
            },
        },

        data() {
            return {
                items: [],
                filters: {},
                categories: [],
            }
        },

        mounted() {
            this.fetchOffices();
        },

        methods: {
            handleChooseAreaChange(key) {
                this.categories = key ? [key] : [];
                this.items = [];
                this.fetchOffices();
            },

            fetchOffices() {
                axios.get('/api/offices', {
                    baseURL: AKTIALKV.siteUrl,
                    params: {
                        region: this.region,
                        categories: this.categories,
                    }
                }).then(({ data }) => {
                    this.items = data.offices;
                    this.filters = data.filters;
                }).catch(error => {
                    console.log(error);
                })
            }
        },

        watch: {
            items() {
                this.$nextTick(() => {
                    const images = this.$el.querySelectorAll('img');
                    // Remove lazyload status attribute so that images are updated.
                    forEach(images, function(el) {
                        el.removeAttribute('data-ll-status');
                    });
                    lazyload.update();

                    const animatedElements = this.$el.querySelectorAll('.animate-on-scroll');
                    new AnimateOnScroll(animatedElements);
                })
            }
        }
    }
</script>
