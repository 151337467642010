var slideIndex = 0;
slideshow();


function slideshow() {
    let slides = document.querySelectorAll(".slide");
    if(slides.length > 0) {
        slides.forEach(function(slide) {
            slide.style.opacity = "0";
        })
        slideIndex++;
        if (slideIndex > slides.length) {slideIndex = 1}
        slides[slideIndex-1].style.opacity = "1";
        setTimeout(slideshow, 6000);
    }
}
