<template>
    <a class="block w-full group" :href="banner.url" style="transform: translateY(30px)">
        <figure class="pb-aspect70 relative mb-5 md:mb-8 overflow-hidden bg-gray-card" :style="{ backgroundColor: banner.bgColor }">
            <img
                class="lazy w-full h-full object-cover object-top absolute top-0 left-0"
                :data-src="banner.image.url"
                :alt="banner.image.alt"
            >
            <div class="w-full h-full bg-black absolute left-0 top-0 opacity-0 group-hover:opacity-25 transition-opacity duration-300"></div>
        </figure>
        <div class="flex flex-col items-center text-center md:px-4">
            <p class="text-sm uppercase mb-3 md:mb-4 text-gray tracking-wide font-calibre-regular">{{ banner.city }}</p>
            <h2 class="text-2xl sm:text-3xl md:text-2xl lg:text-4xl uppercase mb-3 md:mb-4 tracking-widest" v-html="banner.title"></h2>
            <div class="apartment-card-text flex items-center justify-center tracking-normal">
                <p>{{ strings.contactDirectly }}</p>
            </div>
        </div>
    </a>
</template>

<script>
    export default {
        props: {
            banner: {
                type: Object,
                required: true,
            },
            strings: {
                // type: Object,
                validator: prop => typeof prop === 'array' || typeof prop === 'object',
            },
        }
    }
</script>
