// A simple toggle function to toggle classes and texts
// assign HTMLCollection with parents of images with objectFit to variable
const toggleContainer = document.querySelectorAll('[data-toggle-container]')

let toggledOutsideItems = [];

// Loop through HTMLCollection
for (let i = 0; i < toggleContainer.length; i++) {
    const toggle = toggleContainer[i].querySelector('[data-toggle]');

    if (toggle !== null) {
        toggle.onclick = function(e) {
            e.preventDefault();
            const toggleItems = toggleContainer[i].querySelectorAll('[data-toggle-class]');

            for (let j = 0; j < toggleItems.length; j++) {
                const toggleClass = toggleItems[j].getAttribute('data-toggle-class');

                if (toggleItems[j].classList) {
                    toggleItems[j].classList.toggle(toggleClass);
                } else {
                    let classes = toggleItems[j].className.split(' ');
                    let existingIndex = classes.indexOf(toggleClass);

                    if (existingIndex >= 0) {
                        classes.splice(existingIndex, 1);
                    } else {
                        classes.push(toggleClass);
                    }

                    toggleItems[j].className = classes.join(' ');
                }
            }

      // add to list of already toggled items to be closed at a later stage
      if (toggleContainer[i].hasAttribute('data-toggle-outside')) {
        toggledOutsideItems.push({
            item: toggle,
            container: toggleContainer[i]
        });
      }
  }
}
};

// Close all open toggles
document.addEventListener("click", function(event) {
    for (let i = 0; i < toggledOutsideItems.length; i++) {
        const toggle = toggledOutsideItems[i].container.querySelector('[data-toggle]');

        if (toggle !== null && (event.target.closest('[data-toggle]') !== toggledOutsideItems[i].item)) {
            const toggleItems = toggledOutsideItems[i].container.querySelectorAll('[data-toggle-class]');
            for (let j = 0; j < toggleItems.length; j++) {
                const toggleClass = toggleItems[j].getAttribute('data-toggle-class');
                toggleItems[j].classList.toggle(toggleClass);
                toggledOutsideItems.splice(i, 1);
            }
        }
    }
});






//Close on clicking outside navigation area
var dropdownCheckbox = document.querySelector('.show-menu')

document.addEventListener("click", function(e) {
    if(dropdownCheckbox.checked) {
        if(e.target.classList.contains('slide-menu-overlay')) {
            dropdownCheckbox.checked = false;
        }
    }
})
