<template>
    <a class="block w-full group" :class="banner.bannerType + '-card'" :href="banner.url" style="transform: translateY(30px);overflow-x:hidden;">
        <div class="relative mb-10 text-white text-center overflow-hidden">

            <figure
                v-if="banner.image.url"
                class="pb-aspect70 relative"
            >
                <img
                    class="lazy w-full h-full object-cover absolute top-0 left-0"
                    :class="banner.image.class"
                    :data-src="banner.image.url"
                    :alt="banner.image.alt"
                    :style="{ backgroundColor: banner.bgColor }"
                >
            </figure>

            <div v-else class="pb-aspect70 bg-green"></div>
            <div class="w-full h-full bg-black absolute left-0 top-0 opacity-0 group-hover:opacity-25 transition-opacity duration-300"></div>
        </div>
        <div class="text-center px-4 lg:px-8">
            <h3 v-if="banner.articlesCategories" class="text-sm uppercase mb-3 md:mb-4 text-gray tracking-wide font-calibre-regular">{{ banner.articlesCategories }}</h3>
            <h2 class="text-lg sm:text-3xl md:text-xl lg:text-4xl uppercase mb-3 md:mb-4 tracking-widest" v-html="banner.title"></h2>
        </div>
    </a>
</template>

<script>
    export default {
        props: {
            banner: {
                type: Object,
                required: true,
            },
        }
    }
</script>
