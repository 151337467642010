export default function AnimateOnScroll(filter) {
    /*let alHeading = document.querySelector(heading);*/
    let alFilter = document.querySelector(filter);

    if (!alFilter) return;

    window.addEventListener("scroll", function(e) {
        if (window.pageYOffset > 120) {
            /*if(heading) {
                alHeading.classList.remove("lg:opacity-0","lg:h-0","lg:pt-0","lg:pb-0");
                alHeading.classList.add("lg:pt-8","xl:pt-16", "lg:h-25");
            } else */
            if (filter) {
                alFilter.classList.remove("opacity-0", "h-0", "lg:pb-0");
            }
        }
    })
}
