<template>
    <div class="max-w-content-narrow mx-auto">
        <div v-if="showFilters" class="sm:mb-12 md:mb-16 lg:mb-24">
            <ul class="hidden sm:flex hide-scrollbar overflow-x-auto overflow-y-hidden pb-1 sm:justify-center uppercase text-sm -mx-4 sm:mx-0 tracking-wide font-calibre-regular">
                <li v-for="filter, key in filters" class="px-4 sm:px-6 flex-shrink-0">
                    <a
                        @click="handleFilterClick(key)"
                        :class="filterClass(filter.id)"
                        class="pb-1 flex-shrink-0 text-gray hover:text-gray-dark transition-colors duration-300 cursor-pointer"
                    >
                        {{ filter.title }}
                    </a>
                </li>
            </ul>
            <div class="block relative sm:hidden max-w-80 mx-auto">
                <select class="appearance-none absolute top-0 left-0 right-0 bottom-0 z-10 w-full h-full opacity-0 text-base" @change="handleFilterClick($event.target.value)">
                    <option v-for="filter, key in filters" :selected="(selectedFilters.includes(parseInt(filter.id)))" :value="key">{{ filter.title }}</option>
                </select>
                <span class="block border bg-transparent p-6 flex text-sm uppercase tracking-wide font-calibre-regular" v-if="currentFilter">
                    {{ currentFilter.title }}
                    <svg class="ml-auto mr-0" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.707084 6.43501L7.07104 12.799L13.435 6.43501" stroke="#191919" stroke-width="1.5"/>
                    </svg>
                </span>
            </div>
        </div>
        <div v-for="(articles, index) in articleBatches" class="flex flex-wrap" :data-page="index + 1">
            <div v-for="article in articles" class="animate-on-scroll opacity-0 w-full md:w-1/2 md:px-6 lg:px-8 mb-12 lg:mb-16 xl:mb-20" data-scroll-offset=".9">
                <ArticleSkeleton v-if="article.type == 'articleSkeleton'"></ArticleSkeleton>
                <ArticleCard v-else :article="article"></ArticleCard>
            </div>
        </div>
        <InfiniteLoading :identifier="infiniteId" @infinite="fetchArticles"></InfiniteLoading>
    </div>
</template>

<script>
    import Vue from 'vue'
    import axios from 'axios';
    import buildURL from 'axios/lib/helpers/buildURL';
    import _ from 'lodash';
    import forEach from 'lodash/forEach';
    import throttle from 'lodash/throttle';
    import find from 'lodash/find';
    import head from 'lodash/head';
    import LazyLoad from 'vanilla-lazyload';
    import ArticleCard from './ArticleCard';
    import ArticleSkeleton from './ArticleSkeleton';
    import InfiniteLoading from 'vue-infinite-loading';
    import AnimateOnScroll from '../common/animateOnScroll';
    import lazyload from '../common/lazyload';
    import mostlyVisible from '../common/mostlyVisible';

    export default {
        components: {
            ArticleCard,
            ArticleSkeleton,
            InfiniteLoading,
        },

        props: {
            categories: {
                type: Array,
            },
            showFilters: {
                type: Boolean,
                default: true,
            },
            exclude: {
                type: Array,
            },
            limit: {
                validator: prop => typeof prop === 'number' || prop === null,
                default: null,
            },
            pageNum: {
                type: Number,
                default: 1,
            },
            strings: {
                type: Object,
            },
        },

        data() {
            return {
                articleBatches: [],
                filters: {},
                selectedFilters: this.categories,
                pageNumber: 1,
                infiniteId: +new Date(),
                currentPathName: location.pathname,
                currentFilter: {}
            }
        },

        mounted() {
            if (!this.limit) {
                // Add placeholder articles while the real ones are loading.
                this.addDummyArticles(this.pageNum);

                // Fetch previous article pages.
                this.fetchPreviousArticles(this.pageNum);

                // Update page number when scrolling.
                window.addEventListener('scroll', throttle(() => {
                    mostlyVisible
                        .getVisibleElement()
                        .then((element) => {
                            if (element) {
                                this.historyPushState(element.dataset.page);
                            }
                        });
                }, 200), false);
            }
        },

        methods: {
            handleFilterClick(key) {
                let currentFilter = this.filters[key];
                this.selectedFilters = [currentFilter.id];
                this.pageNumber = 1;
                this.articleBatches = [];
                this.infiniteId += 1;
                this.currentPathName = currentFilter.url;
                this.historyPushState();

                this.currentFilter = currentFilter;
            },

            historyPushState(page = null) {
                let articlesPath = this.currentPathName.replace(/\/?(page-\d+)?\/?$/, '');

                if (page) {
                    articlesPath += '/page-' + page;
                }

                let url = buildURL(
                    articlesPath,
                    {},
                    null
                );

                let selectedFilter = _.find(this.filters, ['id', head(this.selectedFilters)]);
                if (typeof selectedFilter !== 'undefined') {
                    let regex = new RegExp(/[\w\såäöÅÄÖ]+/, '');
                    let newTitle = document.title.replace(regex, ' ' + selectedFilter.title + ' ');
                    document.title = newTitle;
                }

                history.pushState({}, null, url);
            },

            fetchArticles($state) {
                let request = axios.get('/api/articles', {
                    baseURL: AKTIALKV.siteUrl,
                    params: {
                        page: this.pageNumber,
                        categories: this.selectedFilters,
                        exclude: this.exclude,
                        limit: this.limit,
                    }
                }).then(({ data }) => {
                    if (data.articles.length) {
                        this.articleBatches.splice(this.pageNumber - 1, 1, data.articles);
                        this.filters = data.filters;
                        this.pageNumber++;
                        if (this.selectedFilters.length) {
                            this.currentFilter = _.find(this.filters, ['id', head(this.selectedFilters)]);
                        } else {
                            this.currentFilter = this.filters[0];
                        }

                        if (this.limit && this.limit <= data.articles.length) {
                            $state.complete();
                        } else {
                            $state.loaded()
                        }

                    } else {
                        $state.complete();
                    }
                }).catch(error => {
                    console.log(error);
                    $state.complete();
                })

                return request;
            },

            async fetchPreviousArticles(pageNumber) {
                let limit = pageNumber;
                let i = (pageNumber - pageNumber);

                // Get the infinite loading component.
                let infiniteLoading = find(this.$children, (c) => {
                    return typeof c.stateChanger !== 'undefined';
                });

                infiniteLoading.stateChanger.complete();

                while (i++ < limit) {
                    await this.fetchArticles(infiniteLoading.stateChanger);
                }

                infiniteLoading.stateChanger.reset();
            },

            filterClass(key) {
                let active = this.selectedFilters.includes(parseInt(key)) || (!this.selectedFilters.length && key == 0);

                return {
                    'border-b': active,
                    'border-gray-lightest': active
                }
            },

            addDummyArticles(pageNumber) {
                while (this.articleBatches.length < pageNumber) {

                    let articles = [];

                    while (articles.length < 24) {
                        articles.push({
                            type: 'articleSkeleton',
                        })
                    }

                    this.articleBatches.splice(pageNumber - 1, 1, articles);
                }
            }

        },

        watch: {
            articleBatches() {
                this.$nextTick(() => {
                    const images = this.$el.querySelectorAll('img');
                    // Remove lazyload status attribute so that images are updated.
                    forEach(images, function(el) {
                        el.removeAttribute('data-ll-status');
                    });

                    lazyload.update();

                    const animatedElements = this.$el.querySelectorAll('.animate-on-scroll');
                    new AnimateOnScroll(animatedElements);

                    // Update page divider elements.
                    const pageDividers = this.$el.querySelectorAll('[data-page]');
                    mostlyVisible.setElements(pageDividers);
                })
            }
        }
    }
</script>
