import Vue from 'vue'
import InfiniteLoading from 'vue-infinite-loading'
import Apartments from './components/Apartments'
import GoogleMap from './components/GoogleMap'
import Brokers from './components/Brokers'
import Offices from './components/Offices'
import Articles from './components/Articles'
import LoadingBars from './components/LoadingBars'

import throttle from 'lodash/throttle';

import './common/footer'
import './common/gallery'
import './common/dropdown'
import './common/slideshow'
import './common/animateOnScroll'
import './common/stickyNavigation'
import './common/pageTransition'
import './common/lazyload'
import './common/csrfToken'

import './components/testimonials'
import './components/apartmentSingleTabs'
import './components/apartmentLandingAnimation'

Vue.use(InfiniteLoading, {
  slots: {
    noMore: '',
    noResults: '',
    spinner: {
      render(createElement) {
        return createElement(LoadingBars);
      },
    },
  },
});

const apartments = document.getElementById('apartments');
if (apartments) {
  new Vue({
    el: apartments,
    components: { Apartments },
    render: function (createElement) {
      const dataset = this.$el.dataset;
      const region = dataset.region ? JSON.parse(dataset.region) : [];
      const quarterOfTownFilter = dataset.quarterOfTownFilter ? JSON.parse(dataset.quarterOfTownFilter) : [];
      const allRegions = dataset.allRegions ? JSON.parse(dataset.allRegions) : [];
      const strings = dataset.strings ? JSON.parse(this.$el.dataset.strings) : [];
      const forRent = dataset.forrent ? JSON.parse(dataset.forrent) : false;
      const showFilters = dataset.showFilters ? JSON.parse(dataset.showFilters) : true;
      const limit = dataset.limit ? JSON.parse(dataset.limit) : null;
      const exclude = dataset.exclude ? JSON.parse(dataset.exclude) : null;
      const pageNum = dataset.pagenum ? JSON.parse(dataset.pagenum) : 1;
      const offset = dataset.offset ? JSON.parse(dataset.offset) : 0;

      return createElement(Apartments, {
        props: {
          region: region,
          quarterOfTownFilter: quarterOfTownFilter,
          allRegions: allRegions,
          strings: strings,
          forRent: forRent,
          showFilters: showFilters,
          limit: limit,
          exclude: exclude,
          pageNum: pageNum,
          offset: offset,
        }
      });
    }
  })
}

const brokers = document.getElementById('brokers');
if (brokers) {
  new Vue({
    el: brokers,
    components: { Brokers },
    render: function (createElement) {
      const dataset = this.$el.dataset;
      const region = dataset.region ? JSON.parse(dataset.region) : [];
      const strings = dataset.strings ? JSON.parse(dataset.strings) : [];
      const showFilters = dataset.showFilters ? JSON.parse(dataset.showFilters) : true;
      const exclude = dataset.exclude ? JSON.parse(dataset.exclude) : null;
      const title = dataset.title;

      return createElement(Brokers, {
        props: {
          region: region,
          strings: strings,
          showFilters: showFilters,
          exclude: exclude,
          title: title,
        }
      });
    }
  })
}

const offices = document.getElementById('offices');
if (offices) {
  new Vue({
    el: offices,
    components: { Offices },
    render: function (createElement) {
      return createElement(Offices, {
        props: {
          strings: JSON.parse(this.$el.dataset.strings),
        }
      });
    }
  })
}

const articles = document.getElementById('articles');
if (articles) {
  new Vue({
    el: articles,
    components: { Articles },
    render: function (createElement) {
      const dataset = this.$el.dataset;
      const categories = dataset.categories ? JSON.parse(dataset.categories) : [];
      const showFilters = dataset.showFilters ? JSON.parse(dataset.showFilters) : true;
      const exclude = dataset.exclude ? JSON.parse(dataset.exclude) : null;
      const limit = dataset.limit ? JSON.parse(dataset.limit) : null;
      const pageNum = dataset.pagenum ? JSON.parse(dataset.pagenum) : 1;
      const strings = dataset.strings ? JSON.parse(dataset.strings) : {};

      return createElement(Articles, {
        props: {
          categories: categories,
          showFilters: showFilters,
          limit: limit,
          exclude: exclude,
          pageNum: pageNum,
          strings: strings,
        }
      });
    }
  })
}

const apartmentMap = document.getElementById('apartment-map');
if (apartmentMap) {
  const markers = JSON.parse(apartmentMap.dataset.markers);
  new GoogleMap('apartment-map', markers);
}

const officesMap = document.getElementById('offices-map');
if (officesMap) {
  const markers = JSON.parse(officesMap.dataset.markers);
  new GoogleMap('offices-map', markers);
}


// Circle animations
const circles = document.querySelectorAll('[data-percentage]');
const transitionDuration = 1000;

circles.forEach(circle => {
  let percentage = parseFloat(circle.dataset.percentage);
  let canAnimate = true;

  strokeTransition(circle, percentage, false);

  window.addEventListener('scroll', throttle(() => {
    // Get the bounding client rectangle position in the viewport
    const bounding = circle.getBoundingClientRect();

    // Checking part. Here the code checks if it's *fully* visible
    // Edit this part if you just want a partial visibility
    if (bounding.bottom <= window.innerHeight) {
      if (canAnimate) {
        strokeTransition(circle, percentage, true);
        increaseNumber(circle, percentage);
        canAnimate = false;
      }
    }
  }, 200), false);

});

function strokeTransition(circle, percentage, animate) {
  let progress = circle.querySelector('.circle-svg__fill');
  let radius = progress.r.baseVal.value;
  let circumference = 2 * radius * Math.PI;
  let offset = circumference - percentage / 100 * circumference;

  progress.style.setProperty('--initialStroke', circumference);
  progress.style.setProperty('--transitionDuration', `${transitionDuration}ms`);
  progress.style.strokeDashoffset = circumference;

  if (animate) {
    setTimeout(() => progress.style.strokeDashoffset = offset, 100);
  }

}

function increaseNumber(circle, number) {
  let element = circle.querySelector('[data-percentage-text]'),
    interval = transitionDuration / number,
    counter = 0;

  let increaseInterval = setInterval(() => {
    if (counter === number) { window.clearInterval(increaseInterval); }

    element.textContent = counter;

    counter++;
  }, interval);
}

// Header lock screen when navigation open
const checkboxes = document.querySelectorAll('#show-menu');

checkboxes.forEach((checkbox) => {
  checkbox.addEventListener('change', function (event) {
    const body = document.querySelector('body');
    if (event.target.checked) {
      // Is checked
      document.body.style.overflowY = "hidden";
    } else {
      // Is not checked
      document.body.style.overflowY = "auto";
    }
  });
})

// SEO submit event to google on form submit, disable submit button before accepting terms

var forms = document.querySelectorAll('form');

forms.forEach((form) => {
  var formHandle = (document.body.classList.contains('sell-apartment')) ? 'contactEstimate' : form.getAttribute('data-handle');
  if (typeof formHandle !== typeof undefined && formHandle !== false) {
    form.addEventListener('submit', function () {
      window.dataLayer.push({
        event: 'formSubmissionSuccess',
        'ui-form-name': formHandle, // Type: String
      });
    });
  }
  var but = document.querySelectorAll('.submit-button')[0];
  var cbs = document.querySelectorAll('#form-input-termsAndConditions');

  function update() {
    cbs.forEach(function (entry) {
      if (entry.checked) {
        but.classList.remove('bg-gray', 'border-gray', 'opacity-25');
        but.classList.add('bg-green', 'border-green');
        return false; // Exit the loop
      } else {
        but.classList.add('bg-gray', 'border-gray', 'opacity-25');
        but.classList.remove('bg-green', 'border-green');
        return false; // Exit the loop
      }
    });
  }

  cbs.forEach(function (entry) {
    entry.onchange = update; // Bind update() function on change of each checkboxes
  });

});




// Toggle main navigation on scroll when on homepage
if (document.querySelector('.homepage')) {

  // Select the input field you want to select
  var inputField = document.getElementById('show-menu');

  // Define a variable to track the last time the scroll event was triggered
  var lastScrollTime = 0;

  // Define the event handler function
  function handleScroll() {
    // Calculate the time difference since the last scroll event
    var currentTime = new Date().getTime();
    var timeDifference = currentTime - lastScrollTime;

    // Check if at least 2 seconds have passed since the last scroll event
    if (timeDifference >= 2000 && inputField.checked === false) {
      console.log("time + focus");
      // Update the last scroll time
      lastScrollTime = currentTime;

      // Check the checkbox and open navigation
      inputField.checked = true;
    }
  }

  // Attach the event listener to the element
  document.addEventListener("touchmove", handleScroll);
  document.addEventListener("scroll", handleScroll);
}

