import Flickity from 'flickity';


window.addEventListener('load', function () {
  var elem = document.querySelector('.main-carousel');
  if (elem) {
    var flkty = new Flickity(elem, {
      // options
      cellAlign: 'left',
      contain: true,
      autoPlay: 3000
    });
  }

  var landingPageTestimonials = document.querySelector('.testimonials-carousel');
  if (landingPageTestimonials) {

    var setTestimonialsTextColor = function (index) {
      var selected = testimonials.cells[index].element;

      if (selected.dataset.color == 'dark') {
        landingPageTestimonials.classList.remove('light-text');
      } else {
        landingPageTestimonials.classList.add('light-text');
      }
    };

    var testimonials = new Flickity(landingPageTestimonials, {
      // options
      prevNextButtons: false,
      wrapAround: true,
      adaptiveHeight: true,
      autoPlay: 8000
    });

    testimonials.resize();

    setTestimonialsTextColor(0);
    testimonials.on('change', (index) => setTestimonialsTextColor(index));

    // Pause / play videos inside of the gallery
    testimonials.on('settle', function () {
      // Loop through all cells
      testimonials.cells.forEach(function (cell) {
        var video = cell.element.querySelector('video');
        if (video) {
          if (cell.element == testimonials.selectedElement) {
            video.play();
          } else {
            video.pause();
          }
        }
      });
    });
  }
})