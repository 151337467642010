import { Loader } from 'google-maps';
import MarkerClusterer from '@google/markerclustererplus';
import map from 'lodash/map';
import each from 'lodash/each';
import filter from 'lodash/filter';
import axios from 'axios';

export default class GoogleMap {

  constructor(elementId, markers = []) {
    this.map = null;
    this.bounds = null;
    this.markers = markers;
    this.infowindows = {};
    this.mapElement = document.getElementById(elementId);

    if (this.mapElement) {
      this.getGoogleMaps()
        .then(() => this.initMap())
        .then(() => this.setMarkers());
    }
  }

  initMap() {
    this.map = new google.maps.Map(this.mapElement, {
      center: { lat: 61.9241, lng: 25.7482 },
      disableDefaultUI: true,
      disableDoubleClickZoom: false,
      mapTypeControl: false,
      overviewMapControl: false,
      panControl: false,
      rotateControl: false,
      scaleControl: false,
      scrollwheel: false,
      streetViewControl: false,
      styles: mapStyles,
      maxZoom: 17,
      zoom: 6,
      zoomControl: true,
      zoomControlOptions: {
        position: google.maps.ControlPosition.RIGHT_CENTER,
        style: google.maps.ZoomControlStyle.LARGE
      }
    });

    this.bounds = new google.maps.LatLngBounds();
  }

  setMarkers() {
    // Filter markers without latitude and longitude.
    this.markers = filter(this.markers, (marker, i) => {
      return (marker.lat && marker.lng);
    });

    // Convert markers to Google maps markers.
    this.markers = map(this.markers, (marker, i) => {
      const iconUrl = require('../../icons/marker-' + marker.status + '.svg');

      const mapMarker = new google.maps.Marker({
        id: marker.id,
        position: {
          lat: parseFloat(marker.lat),
          lng: parseFloat(marker.lng)
        },
        map: this.map,
        icon: {
          url: 'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(iconUrl),
          scaledSize: new google.maps.Size(45, 45),
        },
        info: marker.info
      });

      mapMarker.addListener('click', () => {
        this.handleMarkerClick(i, marker.id);
      });

      this.bounds.extend(mapMarker.getPosition());
      this.map.panTo(this.bounds.getCenter());
      this.map.fitBounds(this.bounds);

      return mapMarker;
    });

    // Cluster markers.
    new MarkerClusterer(this.map, this.markers, {
      styles: clusterStyles,
      clusterClass: 'clustericon',
      maxZoom: 14,
    });
  }

  handleMarkerClick(i, itemId) {
    const marker = this.markers[i];
    // Close other open infowindows.
    each(this.infowindows, (infoWindow, id) => {
      if (itemId != id) {
        infoWindow.close();
      }
    })

    if (!this.infowindows[itemId]) {

      if (marker.info) {

        const infoWindow = new google.maps.InfoWindow({
          content: this.infoWindowContent(marker.info),
        });

        this.infowindows[itemId] = infoWindow;
        infoWindow.open(this.map, marker);

      } else {
        axios.get('/api/apartment/' + itemId, { baseURL: AKTIALKV.siteUrl })
          .then((response) => {

            const desc = `
              <div class="apartment-card-text gm-infowindow__properties">
                ${response.data.price ? '<p>' + response.data.price + '</p>' : ''}
                <p>${response.data.propertySize}</p>
                ${response.data.roomCount ? '<p>' + response.data.roomCount + '</p>' : ''}
                ${response.data.presentationStartDate ? '<p>' + response.data.presentationStartDate + '</p>' : ''}
              </div>
              ${this.apartmentStatus(response.data)}
            `;

            const info = {
              url: response.data.url,
              title: response.data.title,
              desc: desc,
            };

            const infoWindow = new google.maps.InfoWindow({
              content: this.infoWindowContent(info),
            });

            this.infowindows[itemId] = infoWindow;
            infoWindow.open(this.map, marker);
          })
          .catch(error => {
            console.log(error);
          });
      }
    } else {
      const visible = this.infowindows[itemId].getMap() !== null;
      visible ? this.infowindows[itemId].close() : this.infowindows[itemId].open(this.map, marker);
    }
  }

  infoWindowContent(data) {
    return `
            <a href="${data.url}" class="gm-infowindow">
              <h2 class="gm-infowindow__title">${data.title}</h2>
              ${data.desc}
            </a>
        `;
  }

  apartmentStatus(data) {
    return (data.status != 'default' && data.status != 'viewing') ?
      `<p class="gm-infowindow__status">${data.statusText}</p>` :
      '';
  }

  async getGoogleMaps() {
    if (typeof google === 'undefined') {
      const loader = new Loader(AKTIALKV.mapsApiKey);
      return await loader.load();
    }

    return google;
  }
}

const clusterStyles = [
  {
    width: 40,
    height: 40,
  }
];

const mapStyles = [
  {
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#F5F5F5"
      }
    ]
  },
  {
    "elementType": "labels.icon",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#616161"
      }
    ]
  },
  {
    "elementType": "labels.text.stroke",
    "stylers": [
      {
        "color": "#F5F5F5"
      }
    ]
  },
  {
    "featureType": "administrative.land_parcel",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#BDBDBD"
      }
    ]
  },
  {
    "featureType": "poi",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#EEEEEE"
      }
    ]
  },
  {
    "featureType": "poi",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#757575"
      }
    ]
  },
  {
    "featureType": "poi.park",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#E5E5E5"
      }
    ]
  },
  {
    "featureType": "poi.park",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#9E9E9E"
      }
    ]
  },
  {
    "featureType": "road",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#FFFFFF"
      }
    ]
  },
  {
    "featureType": "road.arterial",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#757575"
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#DADADA"
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#616161"
      }
    ]
  },
  {
    "featureType": "road.local",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#9E9E9E"
      }
    ]
  },
  {
    "featureType": "transit.line",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#E5E5E5"
      }
    ]
  },
  {
    "featureType": "transit.station",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#EEEEEE"
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#B7C5CC"
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#9E9E9E"
      }
    ]
  }
];
