import throttle from 'lodash/throttle';

export default class AnimateOnScroll {

    constructor(elements) {
        this.elements = elements;
        this.handleScroll();

        window.addEventListener('scroll', throttle(() => this.handleScroll(), 200), false);
    }

    handleScroll() {
        // console.log(this.elements);

        for (let i = 0; i < this.elements.length; i += 1) {
            if (this.isInViewport(this.elements[i]) && !this.elements[i].classList.contains('in-view')) {
                this.elements[i].classList.add('in-view');
            }
        }
    }

    isInViewport(element) {
        // Get the bounding client rectangle position in the viewport
        const bounding = element.getBoundingClientRect();

        //Using vh
        const scrollOffset = (element.hasAttribute("data-scroll-offset")) ? document.documentElement.clientHeight * parseFloat(element.getAttribute('data-scroll-offset')) : 0;

        // Checking part. Here the code checks if it's *fully* visible
        // Edit this part if you just want a partial visibility
        if (bounding.top <= scrollOffset) {
            return true;
        } else {
            return false;
        }
    }

}

const elements = document.querySelectorAll('.animate-on-scroll');

window.addEventListener('load', function() { new AnimateOnScroll(elements)});
